import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const NikkeGuidesExia: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Exia (Treasure) guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_exia_tr.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Exia (Treasure) guide & review</h1>
          <h2>
            A guide & review for Exia (Treasure) in NIKKE: Goddess of Victory.
          </h2>
          <p>
            Last updated: <strong>04/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Exia (Treasure)" />
              <div className="employee-container for-nikke">
                <NikkeCharacter
                  mode="icon-tier"
                  slug="exia"
                  enablePopover
                  tierID={36}
                />
              </div>
              <p>
                Who doesn't love gamer girls? As we watch Exia's passion for
                gaming lead us Noobs to a small gaming adventure alongside her,
                we also get to see our gloomy gamer girl gain some interesting
                skills from her favorite item. These skills have not only
                improved her support capabilities, but they have also
                significantly bettered her as a DPS unit, making her relevant
                against some bosses in the huge rosters we have in the current
                PvE meta. Hold on to your gaming consoles as we try to break
                down and analyze just how much better of a unit she is now!
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                Exia can now be considered a strong support and a DPS for
                Electric-Weak bosses (i.e. Water Element Bosses). Her Phase 1
                alters her Skill 2 in an amazing way, increasing the value of
                her self ATK buff significantly, while also making her an ATK
                buffer for her electric element allies. When paired with her
                Phase 2's increased damage taken debuff, all of this combines to
                allow Exia to deal significant damage, out-DPSing the likes of
                Scarlet! Not only is she doing that, but she is also helping her
                electric element allies deal at least 25-30% more damage, which
                we can add to Exia's total, making her an immense addition to
                our rosters. While she is auto-able, her true potential DPS can
                be unlocked by manualling her like one would manual Trony: with
                a mix of full charged and quick fire shots. There is another
                caveat regarding Exia's DPS. While she is stronger than Scarlet,
                Scarlet herself is not considered meta in the first place
                against solo bosses currently. So while Exia does have a spot in
                electric raids, it comes at the expense that she may not be used
                against other elements.
              </p>
              <h5>Should I get the Favorite Item for this unit?</h5>
              <p>
                If you have any sense of competitiveness in Co-op and Solo
                Raids, our answer is a resounding: YES! Exia is the Best-In-Slot
                Burst 1 unit for Electric Weak Co-op battles as of right now! In
                Solo Raids, in Electric Weak Raids she will again come into play
                as she not only manages to Out-DPS Scarlet herself, but can also
                support her Electric Element team-mates to deal more damage as
                well!
              </p>
              <p>
                As of the release of this review, Anis: Sparkling Summer's
                banner is also active. For the best usage of Exia's treasure
                form, we recommend pulling at least a copy of S.Anis, without
                whom Exia will fail to reach her potential.
              </p>
              <p>
                We recommend getting Exia to at least Phase 2 Favorite Item
                before using her, as it unlocks her Burst Skill's damage taken
                debuff, which helps each member of her team deal more damage,
                remarkably increasing Exia's value in the team. While her
                potential flexbility can be increased by reaching Phase 3, we
                think Phase 2 is definitely enough.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <StaticImage
                src="../../../images/nikke/reviews/exia_treasure_1.webp"
                alt="Kit"
              />
              <h5>Phase 1 - Skill 2</h5>
              <blockquote>
                <p>
                  ■ Activates when landing an attack with Full Charge. Affects
                  self.
                </p>
                <p>
                  Collect Hacking Code: ATK ▲ 28%, stacks up to 5 time(s) for 5
                  sec.
                </p>
                <p>
                  ■ When the last round of ammunition hits, affects all Electric
                  Code ally units if the caster is in Collect Hacking Code.
                </p>
                <p>
                  ATK ▲ 5.8% of caster's ATK, stacks up to 5 time(s) for 15 sec.
                </p>
              </blockquote>
              <p>
                The key to unlocking Exia's potential is given to us right off
                the bat. Everything about this skill is impressive. Firstly, it
                enhances the value of the ATK buff given by Collect Hacking Code
                substantially, with Exia giving herself an astounding 140% ATK
                buff at lvl10 Skill 2! This can be considered a virtually
                permanent uptime buff as well since it stacks after every Full
                charged shot. Alongside this, a last bullet skills is introduced
                which stacks ATK buffs that buff all electric allies in her
                team. This allows Exia to not only act as a DPS, but also a
                support unit!
              </p>
              <p>
                Since a last bullet skill is mixed with a skill requiring full
                charged shots, the ideal way to play with Exia is similar to how
                one would play with Trony: a mix of full charged shots and quick
                fire animation cancel shots. You can find a showcase on how to
                properly reach Exia's potential DPS in the following stream,
                (timestamp 6:06:50):
              </p>
              <Row className="video-row">
                <Col xs={12} lg={6}>
                  <YoutubeEmbed embedId="2lEkzi6Atwc" />
                </Col>
              </Row>
              <p>Timestamp Link - ?t=22010s</p>
              <h5>Phase 2 - Burst Skill</h5>
              <blockquote>
                <p>■ Affects 10 enemy unit(s) with the highest DEF.</p>
                <p>Deals 122.32% of final ATK as damage.</p>
                <p>DEF ▼ 2.71% for 5 sec.</p>
                <p>
                  ■ Activates when Collect Hacking Code is fully stacked.
                  Affects the same target(s).
                </p>
                <p>Deals 122.32% of final ATK as additional damage.</p>
                <p>Damage Taken ▲ 18.04% for 10 sec.</p>
              </blockquote>
              <p>
                Phase 2 adds only one single ability, which is an all-important
                increased Damage Taken debuff which can inflict 10 enemies. This
                enhances Exia's ability to support her teammates damage. Since
                this is usually an undiluted buff, it is highly appreciated. It
                is also worthy to note that all of her allies can take advantage
                of this buff, and not just her Electric allies.
              </p>
              <h5>Phase 3 - Skill 1</h5>
              <blockquote>
                <p>
                  ■ Activates when the last bullet hits the target. Affects the
                  target if the caster is in Collect Hacking Code.
                </p>
                <p>ATK ▼ 13.77% for 5 sec.</p>
                <p>DEF ▼ 13.77% for 5 sec.</p>
                <p>
                  ■ Activates at the start of Full Burst Time. Affects self.
                </p>
                <p>Fixed to increase Reloading Speed by 95% for 10 sec. </p>
              </blockquote>
              <p>
                The simplest of all improvements, a simple and effective reload
                speed buff is added to Exia's Skill 1. It has both high value
                and high uptime since it lasts for the entirety of the usual 10s
                of Full Burst. This helps Exia keep her stacks up for Collect
                Hacking Code extremely easily and without pressure to be in high
                Reload Speed teams, increasing her flexibility. Even though
                currently she is going to be used alongside S.Anis, the future
                beholds many units that can possibly steal Exia away from her!
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Tier List" />
              <h5>
                Campaign (LD) - <strong className="a">A</strong> | Campaign (HD)
                - <strong className="a">A</strong>
              </h5>
              <p>
                Exia's damage taken debuff can hit 10 enemies, which
                automatically makes her a decent choice. Her ability to give
                huge ATK buffs to herself as well as her electric element allies
                (prominently Scarlet, S.Anis or Ein) boosts her viability even
                more. However, some things that reduce the viability of using
                Exia in Campaign are the lack of CDR, lack of heals, and a lack
                of undiluted buffs such as Attack Damage buff, Pierce Damage
                buff, etc. that would have made her remarkably better in
                Campaign. While her Damage taken debuff is undiluted, it is of
                notably lower value than the likes of Blanc, or even S. Rosanna.
                She also lacks ATK buffs for some of the best B3 units for
                campaign, namely Red Hood, Modernia and Alice, making her more
                niche than other supporters. Overall, while Exia is a decent
                option, better options definitely exist in the current meta,
                especially in Liter and D: Killer Wife.
              </p>
              <h5>
                Boss (Solo) - <strong className="s">S</strong> | Boss (Adds) -
                <strong className="s">S</strong>
              </h5>
              <p>
                A niche for Water Element Bosses in raids and co-op, but
                definitely finds a permanent spot in the current roster against
                them. Electric Weak Bosses is Exia's actual forte. While she has
                a slot against Electric Weak bosses, her performance against
                other elements is still good. If you lack key DPS units, or are
                unlucky enough to not have some of the limited units, Exia can
                definitely help you make a team 5 for raids alongside S.Anis and
                Ein/Scarlet. Her self DPS is not to be underestimated. Due to
                the fact that Exia can Burst every cycle, she can receive double
                the buffs from Crown's S1 compared to any B3! This allows her to
                deal significant damage, outdpsing the likes of even Scarlet!
                This is just a bonus for her, as this comes alongside her damage
                taken debuffs on enemies and ATK buffs for the team!
              </p>
              <StaticImage
                src="../../../images/nikke/reviews/exia_treasure_2.webp"
                alt="Kit"
              />
              <p>
                <i> Source: X (formerly Twitter) user: @waro_nikke</i>
              </p>
              <p>
                Against Bosses with adds, her performance can falter since
                stages with small mobs demand more from B1s than what Exia can
                provide. However, she is still a decent choice for it, and can
                excel in them especially when the stage is electric weak. Since
                the major focus is on the boss, and her Damage Taken targets
                Highest DEF units, she lands her debuffs on the boss every time,
                making sure that any damage that targets the boss is buffed to
                the max.
              </p>
              <h5>
                PVP - <strong className="c">C</strong>
              </h5>
              <p>
                Exia shows similar performance to her non-treasured form in PvP,
                with the key difference being that she gains ATK buffs every
                shot she hits. This makes her ever so slightly more usable, but
                still not enough to break into the usable tiers.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <div className="nikke-skills">
                <div className="nikke-skills-row over header">
                  <div className="column character">Character</div>
                  <div className="column suggestions essential">Essential</div>
                  <div className="column suggestions ideal">Ideal</div>
                  <div className="column suggestions passable">Passable</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-row">
                  <div className="nikke-skills-row over data">
                    <div className="column character">
                      <NikkeCharacter
                        slug="exia"
                        mode="icon-tier"
                        showLabel
                        enablePopover
                        onlyName
                        tierID={36}
                      />
                    </div>
                    <div className="column suggestions pve">
                      <p className="on-mobile">Essential</p>
                      <p>
                        <strong>MUST NOT HAVE MAX AMMO</strong>
                      </p>
                    </div>
                    <div className="column suggestions pvp">
                      <p className="on-mobile">Ideal</p>
                      <p>
                        <strong>4x Elemental Damage</strong>
                      </p>
                      <p>
                        <strong>4x Attack</strong>
                      </p>
                    </div>
                    <div className="column suggestions pvp">
                      <p className="on-mobile">Passable</p>
                      <p>
                        <strong>4x Charge Speed</strong>
                      </p>
                    </div>
                    <div className="column priority">
                      <p className="on-mobile">Priority</p>
                      <p className={`prio prio-5`}>Medium (PvE)</p>
                    </div>
                  </div>
                  <div className={`explanation`}>
                    <p className="on-mobile">Notes</p>
                    <p>
                      Elemental Damage and ATK better her DPS performance. Niche
                      DPS+Support for Electric Weak Raids. Last bullet effect
                      means she does not want Max Ammo effect on OL gear! If she
                      has Max Ammo on her gear, you cannot Full Auto her. Charge
                      Speed is a passable effect that helps her if you want to
                      auto her.
                    </p>
                  </div>
                </div>
              </div>
              <br></br>
              <h5>Skill Investments</h5>
              <ul>
                <li>
                  <strong>Skill 1: 1</strong>
                </li>
                <ul>
                  <li>
                    {' '}
                    First part of this skill is basically useless, however the
                    reload speed in Phase 3 is extremely helpful if she is being
                    used without any Reload Buffers. Fortunately, it is fixed at
                    95% Reload Speed at each level. Hence, we do not need to
                    invest in this skill.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 7/10</strong>
                </li>
                <ul>
                  <li>
                    This is where Exia receives her DPS from. This is where not
                    just her, but her electric allies as well increase their DPS
                    from. We recommend eventually maxxing out this skill to 10.
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill: 4/7/10</strong>
                </li>
                <ul>
                  <li>
                    Her burst provides a decent damage taken debuff which helps
                    all of her allies to deal more damage. We also recommend
                    eventually maxxing out this skill.
                  </li>
                </ul>
              </ul>
              <br></br>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="exia" />
                </div>
              </div>
              <br />
              <br></br>
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_3_sm.png"
                alt="Guide"
              />
              <p>
                Resilience Cube should be the best option for Exia. Adjutant
                cube can be a viable replacement if one does not have enough
                slots for Resilience cube.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: Co-op Craziness</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon-tier"
                      slug="exia"
                      enablePopover
                      tierID={36}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      slug="sparkling-summer-anis"
                      enablePopover
                      mode="icon"
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="sparkling-summer-anis"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="ein" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="volume" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                The best Electric team that one can make for Bosses. Do not be
                scared seeing S.Anis two times. She is there twice due to the
                possibility of using her in Co-op in 2 slots. Multiple Electric
                Weak co-ops have gone by where this team has been used to
                achieve the top scores!
              </p>
              <h5>Team #2: Crown was Taken</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon-tier"
                      slug="exia"
                      enablePopover
                      tierID={36}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      slug="sparkling-summer-anis"
                      enablePopover
                      mode="icon"
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="ein" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                Again for bossing. If Crown is needed in another team, we can
                make use of Biscuit for heals and Dolla/S.Helm for CDR. Biscuit
                also provides offensive buffs for both Exia and S.Anis, and is
                benefitted from both Exia's and S.Anis' buffs that they give to
                electric allies! Biscuit's cover heal is used to keep Ein and
                Dolla/S.Helm alive
              </p>
              <h5>Team #3: I do not like using Snow White - Yulha teams</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon-tier"
                      slug="exia"
                      enablePopover
                      tierID={36}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      slug="sparkling-summer-anis"
                      enablePopover
                      mode="icon"
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="helm" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
                  </div>
                </div>
              </div>
              <p>Alternative team:</p>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon-tier"
                      slug="exia"
                      enablePopover
                      tierID={36}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      slug="sparkling-summer-anis"
                      enablePopover
                      mode="icon"
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="ein" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="alice-wonderland-bunny"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                Another option for team 5 in Solo Raids, with the Scarlet Helm
                team making its debut just a couple weeks ago in the Golden
                Kraken Solo Raid. If Snow White-Yulha teams are harder to
                execute, or against bosses where pierce is less prominent, this
                team gives a viable replacement for the notorious yet undeniably
                good SW-Yulha combo.
              </p>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Possesses innate ATK buffs.</li>
                        <li>
                          Acts as a DPS + support hybrid, providing ATK buffs to
                          allies while dealing decent damage herself.
                        </li>
                        <li>
                          All of her buffs are of high value and have permanent
                          uptime.
                        </li>
                        <li>
                          Inflicts Damage-taken debuff, one of the best debuffs,
                          on enemies, which allows herself and her team to do
                          more damage,
                        </li>
                        <li>
                          20s B1 DPS, allowing her to recieve twice the ATK
                          buffs from Crown compared to other B3 DPS.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Manual-intensive if one wants to reach her DPS
                          potential.
                        </li>
                        <li>Supporting ATK buffs only buff Electric units.</li>
                        <li>
                          On non electric-weak bosses, she will have a hard time
                          fitting into the top meta teams.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesExia;

export const Head: React.FC = () => (
  <Seo
    title="Exia (Treasure) guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Exia (Treasure) in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
